/**
 * Created by Yosef Nussen
 * Date: 7/28/20
 */
 import React from "react";
 import Table from "@material-ui/core/Table";
 import TableBody from "@material-ui/core/TableBody";
 import TableCell from "@material-ui/core/TableCell";
 import TableContainer from "@material-ui/core/TableContainer";
 import TableHead from "@material-ui/core/TableHead";
 import TableRow from "@material-ui/core/TableRow";
 import Paper from "@material-ui/core/Paper";
 import ReportCardFront from "./ReportCardFront";
 import ReportCardTable from "./ReportCardTable";
 import baseUrl, {
  isTTOL,
   authentication,
   reportCardSchoolName,
   reportCardSchoolAddress,
   reportCardLogo,
 } from "../Utils"; 
import ReportCardComments from "./ReportCardComments";
 
 class ReportCard extends React.Component {
   today = new Date();
   constructor() {
     super();
     // this.getReportCard()
     // this.getComments()
   }
   componentDidUpdate(prevProps) {
     if (this.props.studentId !== prevProps.studentId) {
       this.getReportCard();
       this.getComments();
     }
   }
   /*    componentWillReceiveProps(nextProps) {
         console.log(nextProps)
         this.getReportCard()
         this.getComments()
 
         //this.setState({ studentData: nextProps.studentData },this.setRows());
 
     }*/
   state = {
     studentData: [],
     rows: [],
     columns: [],
     comments: [],
     term1Comment: "",
   };
   getReportCard() {
     fetch(`${baseUrl}/report-card/${this.props.studentId}`, {
       headers: {
         Authorization: authentication,
       },
     })
       .then((response) => {
         return response.json();
       })
       .then((res) => {
         this.setState({ studentData: res });
         this.setRows();
       });
   }
   getComments() {
     fetch(`${baseUrl}/reportcard-comments/${this.props.studentId}`, {
       headers: {
         Authorization: authentication,
       },
     })
       .then((response) => {
         return response.json();
       })
       .then((res) => {
         res.map((comment) => {
           if (comment.term_id == 1) {
             this.setState({ term1Comment: comment });
           }
         });
         this.setState({ comments: res });
       });
   }
   //[{"id":1,"name":"Math","terms":[{"id":1,"term_order":1,"term":"Term 1","mark":"D"},{"id":2,"term_order":2,"term":"Term 2","mark":"#Div\/0"}]},{"id":2,"name":"Science","terms":[{"id":1,"term_order":1,"term":"Term 1","mark":""},{"id":2,"term_order":2,"term":"Term 2","mark":"#Div\/0"}]}]
   setRows() {
     let rows = [];
     let columns = [];
     this.state.studentData.map((subject, x) => {
       let row = [subject.name];
       subject.terms.map((term, index) => {
         if (x == 0) {
           columns.push(term.term);
         }
         row.push(term.mark);
       });
       rows.push(row);
     });
 
     this.setState({ rows: rows, columns: columns });

   }
   /* createData(name, calories, fat, carbs, protein) {
         return { name, calories, fat, carbs, protein };
     }*/
 
   /* rows = [
         createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
         createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
         createData('Eclair', 262, 16.0, 24, 6.0),
         createData('Cupcake', 305, 3.7, 67, 4.3),
         createData('Gingerbread', 356, 16.0, 49, 3.9),
     ];*/
   render() {
     const classes = {
       table: {
         width: "3in",
       },
     };
     return (
       <div>
         {" "}
         <div
           style={{
            width: "11in",
            height: "8.286in",
             pageBreakBefore: "always",
           }}
         >
           <div
             className="row"
             style={{ marginTop: ".5in", marginLeft: ".5in"}}
           >
             <div
               style={{
                 width: "4.5in",
                 display: "inlineBlock",
                 overflow: "hidden",
               }}
             >
               { this.props.showComments &&
                <ReportCardComments 
                term1Comment={this.props.term1Comment}
                term2Comment={this.props.term2Comment}
                term3Comment={this.props.term3Comment}
                />
               }

               <div>

                 <div
               style={{
                 width: "4.5in",
                //  display: "inlineBlock",
                //  height: '50%',
                 overflow: "hidden",
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'space-between',
                 border: '1px, solid, gray'
               }}
             >

               <ReportCardTable
                columns={this.props.columns}
                rows={this.props.rows}
                titel={"Subject"}
               />

               {isTTOL && <div style={{border: '1px solid black', width: '100%', height: '70px', fontSize: 10, marginTop: 12, marginBottom: 12}}><h5 style={{marginBottom: 0}}>Grade Key</h5><b>A+=97-100, A=93-96, A-=90-92, B+=87-89, B=83-86, B-=80-82, C+=77-79, C=73-76, C-=70-72, D+=67-69, D+=63-66, D=60-62, F{'=<59'}</b></div>}
             </div> 

              <div  style={{
                     width: "95%",
                     marginTop: ".75in",
                   }}
              >
                   <ReportCardTable
                      columns={this.props.behaviorColumns}
                      rows={this.props.behaviorRows}
                      title={"Behavior"}
                   />
                      </div>
                      <div><br></br></div>
                      <div>
                      <ReportCardTable
                      columns={this.props.columns}
                      rows={this.props.honorRole}
                      />
               </div>
               <div  style={{paddingTop: (!isTTOL) ? '10px' : 0}} ><p> * = Modified </p></div>
               </div> 
             </div> ,
             <div
               style={{
                 margin: "0px",
                 border: "10px double",
                 height: "7.5in",
                 width: "4.5in",
                 display: "inlineBlock",
                 marginLeft: ".9in",
                 overflow: "hidden",
               }}
             >
               <ReportCardFront 
                grade = {this.props.grade}
                lastName = {this.props.lastName}
                firstName = {this.props.firstName}
               />
             </div>
             </div>

           </div>
         <div style={{ width: "11in", height: "8.286in" }}>
           <div
             className="row"
             style={{ marginLeft: ".5in", marginTop: -40 }}
           >
             {/*marginLeft:"1in",*/}
             {/*<div style={{paddingTop:".5in"}}>*/}
             <>
             <div
               style={{
                 width: "4.5in",
                //  display: "inlineBlock",
                //  height: '50%',
                 overflow: "hidden",
                 display: 'flex',
                 flexDirection: 'column',
                 justifyContent: 'space-between',
               }}
             >

              <ReportCardTable
                columns={this.props.columns}
                rows={this.props.rows}
                title={"Subject"}
               />
               <div><br></br></div>

                <ReportCardTable
                  columns={this.props.columns}
                  rows={this.props.honorRole}
                />

              
               {isTTOL && <div style={{border: '1px solid black', width: '100%', height: '70px', fontSize: 10, marginTop: 12, marginBottom: 12}}><h5 style={{marginBottom: 0}}>Grade Key</h5><b>A+=97-100, A=93-96, A-=90-92, B+=87-89, B=83-86, B-=80-82, C+=77-79, C=73-76, C-=70-72, D+=67-69, D+=63-66, D=60-62, F{'=<59'}</b></div>}
             </div> 
<div
style={{
  margin: "0px",
 //  height: '50%',
 //  display: "inlineBlock",
 //  height: "7.5in",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: "4.5in",
  marginLeft: ".9in",
  overflow: "hidden",
}}
>
<div style={{ width: "100%", height: "98%" }}>
  <div>
    <ReportCardTable
    columns={this.props.behaviorColumns}
    rows={this.props.behaviorRows}
    title={"Behavior"}
    />
       </div>
      </div>
       {isTTOL && <div style={{border: '1px solid black', height: '70px', fontSize: 10, width: '100%', marginBottom: 12}}><h5 style={{marginBottom: 0}}>Behavior Scale</h5><b>S+ = Satisfactory Plus, S = Satisfactory, S- = Satisfactory Minus, NI = Needs Improvement</b>
       </div>}
    {/*</div>*/}
</div> 
</>

             <div  style={{paddingTop: (!isTTOL) ? '10px' : 0}} ><p> * = Modified </p></div>
           </div>
         </div> 
       </div>
     );
   }
 }
 export default ReportCard;
 